<template>
    <div>
        <s-crud 
            title="ESTACIONES DE TRABAJO"
            excel
            edit
            add 
            :filter="filter"
            searchInput
            sortable
            :config="config"
            remove                   
            @save="save($event)">
            <template scope="props">
                <v-card>
                    <v-card-text>
                        <v-row>                        
                            <v-col cols="4" lg="4" md="6" sm="12">
                                <s-text
                                    label="Descripcion"
                                    v-model="props.item.PwsDescription"
                                    ref="txtPwsDescription"
                                    
                                ></s-text>
                            </v-col>
                            <v-col cols="4" lg="4" md="6" sm="12">
                                <s-select-definition                                 
                                    :def=1154 
                                    label="Estado" 
                                    v-model=props.item.PwsStatus>
                                </s-select-definition>  
                            </v-col>                        
                        </v-row>
                    </v-card-text>
                </v-card>
            </template>
            <template v-slot:PwsStatus="{ row }">
                <v-icon :color="row.PwsStatus == 1 ? 'success': 'default'">mdi-checkbox-blank-circle</v-icon>
            </template>
        </s-crud>
    </div>
</template>


<script>
    
    import _sWorkStation from '@/services/FrozenProduction/FrzPackingWorkStation.js'
    export default {
        components:{},     
        data(){
            return{
                config:{
                    service: _sWorkStation,
                    model:{
                        PwsID: "ID",
                        PwsStatus: "PwsStatus"
                    },
                    headers:[
                        {text: "ID", value: "PwsID",  align: "center", width: "10%", sortable: true},
                        {text: "Descripcion", value: "PwsDescription",  align: "center", width: "80"},
                        {text: "Estado", value: "PwsStatus",  align: "center", width: "30"},
                    ]
                },
                filter:{

                }
            }
        },methods:
        {
            save(item)
            {
                if(item.PwsDescription == "" || item.PwsDescription == undefined)
                {
                    this.$fun.alert("Debe ingresar una descripcion", "question");
                    this.$refs.txtPwsDescription.focus(); 
                    return;
                }
                
                item.save();                    
            }
        }
    }
</script>